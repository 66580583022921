import React from "react"
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import Button from "../../components/Button"

const Header = styled.h2`
  color: #f7ec00;
  font-family: Outfit, sans-serif;
  display: inline-block;
  opacity: 0.5;
`
const Description = styled.p`
  color: #fff;
  max-width: 340px;
`
const Highlight = styled.span`
  background: #f8ea00;
  padding: 2px 5px;
  color: #444;
  border-radius: 4px;
`

class Beams extends React.Component {
  componentDidMount() {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    const scene = new THREE.Scene()

    const textureLoader = new THREE.TextureLoader()
    const shadowTexture = textureLoader.load("/assets/simpleShadow.jpg")

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    this.mount.appendChild(renderer.domElement)

    /**
     * Lights
     */
    // Ambient light
    const ambientLight = new THREE.AmbientLight("#b9d9ff", 0.4)
    scene.add(ambientLight)

    /**
     * Geometry
     */

    const beamGeometry = new THREE.CylinderGeometry(0.01, 0.03, 1, 16, 16, true)
    const beamsGroup = new THREE.Group()

    const beamColors = [
      0xf9e400,
      0x40ff00,
      0xffb2ce,
      0x00dafa,
      0xff6933,
      0xad50ff,
    ]

    scene.add(beamsGroup)

    this.addBeam = () => {
      for (let b = 0; b < 10; b++) {
        const parameters = { ringRadius: (Math.random() - 0.5) * 5 + 12 }
        const color = beamColors[Math.floor(Math.random() * beamColors.length)]

        const beams = new THREE.Group()
        beamsGroup.add(beams)

        const lightBeams = new THREE.Group()
        lightBeams.custom = { type: "beams" }
        beams.add(lightBeams)

        const angle = Math.random() * Math.PI * 2
        const positionX =
          Math.sin(angle) * parameters.ringRadius + (Math.random() - 0.5) * 2
        const positionY = 0.2 + Math.random() * 0.01
        const positionZ =
          Math.cos(angle) * parameters.ringRadius + (Math.random() - 0.5) * 2

        beams.position.set(positionX, positionY, positionZ)

        const material = new THREE.MeshBasicMaterial({
          color,
          transparent: true,
        })

        beams.custom = {
          maxHeight: Math.random() * 2 + 15,
          minHeight: Math.random(),
          growFactor: Math.random() - 0.5 + 0.8,
          opacity: 1 - Math.random(),
        }

        const shadowMaterial = new THREE.MeshBasicMaterial({
          alphaMap: shadowTexture,
          transparent: true,
          color,
          depthWrite: false,
        })
        const beamBase = new THREE.Mesh(
          new THREE.PlaneGeometry(1.5, 1.5, 4, 4),
          shadowMaterial
        )
        beamBase.rotation.x = -0.5 * Math.PI
        beamBase.position.y = -0.1

        const sizeScale = Math.random() * 3 + 1
        beamBase.scale.set(sizeScale, sizeScale, sizeScale)
        beamBase.material.opacity = beams.custom.opacity
        beams.add(beamBase)

        const centralBeam = new THREE.Mesh(beamGeometry, material)
        centralBeam.scale.set(2, 1 + Math.random(), 2)
        centralBeam.position.y = 0.5 * centralBeam.scale.y
        lightBeams.add(centralBeam)

        for (let i = 0; i < Math.ceil(sizeScale) * 2; i++) {
          const beam = new THREE.Mesh(beamGeometry, material)
          const angle = Math.random() * Math.PI * 2
          const randomY = Math.random() + 0.5
          beam.position.set(
            Math.sin(angle) * 0.5 * sizeScale,
            randomY * 0.5,
            Math.cos(angle) * 0.5 * sizeScale
          )
          beam.scale.set(0.3, randomY, 0.3)
          beam.material.opacity = beams.custom.opacity
          lightBeams.add(beam)
        }
      }
    }

    this.addBeam()

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    )

    camera.position.x = 0
    camera.position.y = 10
    camera.position.z = 20

    scene.add(camera)

    const clock = new THREE.Clock()

    const tick = () => {
      const elapsedTime = clock.getElapsedTime()
      beamsGroup.children.forEach(group => {
        const targetGroup = group.children.find(
          c => c.custom && c.custom.type === "beams"
        )
        if (!targetGroup) return

        const newScaleY =
          Math.abs(Math.sin(elapsedTime * group.custom.growFactor)) *
            group.custom.maxHeight +
          group.custom.minHeight
        targetGroup.scale.set(1, newScaleY, 1)

        if (newScaleY <= Math.random()) {
          group.parent.remove(group)
        }
      })

      camera.position.x = Math.sin(elapsedTime * 0.25) * 20
      camera.position.z = Math.cos(elapsedTime * 0.25) * 20

      // Update controls
      controls.update()

      // Render
      renderer.render(scene, camera)

      // Call tick again on the next frame
      window.requestAnimationFrame(tick)
    }
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true

    renderer.domElement.addEventListener("click", () => {
      this.addBeam()
    })
    renderer.domElement.addEventListener("touchstart", () => {
      this.addBeam()
    })
    tick()

    window.addEventListener("resize", () => {
      if (window.innerWidth >= 500) {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      }
    })
  }
  render() {
    return (
      <div>
        <div
          ref={ref => (this.mount = ref)}
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            padding: 8,
            left: 30,
            borderRadius: 8,
          }}
        >
          <Header>The Uncanny Counter Beams</Header>
          <Description>
            Finished binge-watching the{" "}
            <Button
              href="https://en.wikipedia.org/wiki/The_Uncanny_Counter"
              target="_blank"
              rel="noopener noreferrer"
            >
              Netflix drama
            </Button>
            last week and that CG made me want to re-create the Territory scene
            lol...so this is a three.js version.
            <br />
            <br />
            <Highlight>Click anywhere</Highlight> to "summon" more Territory
            (mega-lols). Controls with scroll-wheel in Y-axis camera position -
            the perspective view is such a surprise.
            <br />
            <br />
            Love the blending of shadows when overlap. The Neon palette is 😍...
          </Description>
        </div>
      </div>
    )
  }
}
const UncannyCounterBeam = ({ location }) => {
  return (
    <Layout location={location} navPosition="absolute">
      <SEO title="Uncanny Counter Beam" />
      <Beams />
    </Layout>
  )
}
export default UncannyCounterBeam
